import React, { useEffect, useState } from "react";
import apiClient from "utils/apiClient"; 
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Distribution, Point, Produit, Traduction } from "model/models";
import SectionHero3 from "components/SectionHero/SectionHero3"; 
import milk from "images/amechtil/milk.png"
import yagurt from "images/amechtil/yogurt.png"
import cahier from "images/amechtil/cahier.png"
import boire from "images/amechtil/boire.png"
import milkW from "images/amechtil/milkW.png"
import yagurtW from "images/amechtil/yogurtW.png"
import cahierW from "images/amechtil/cahierW.png"
import boireW from "images/amechtil/boireW.png"
import milkFr from "images/amechtil/milkFr.png"
import yagurtFr from "images/amechtil/yogurtFr.png"
import cahierFr from "images/amechtil/cahierFr.png"
import boireFr from "images/amechtil/boireFr.png"
import milkFrW from "images/amechtil/milkFrW.png"
import yagurtFrW from "images/amechtil/yogurtFrW.png"
import cahierFrW from "images/amechtil/cahierFrW.png"
import boireFrW from "images/amechtil/boireFrW.png"
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
 import { useTranslation } from "react-i18next";
import MapComponent from "./Map";
import Heading from "shared/Heading/Heading";
import { trace } from "console";
import { useTraductionContext } from "components/TraductionContext";
import Footer from "shared/Footer/Footer";
import SectionVideos from "./SectionVideos";
import Loadingh from "components/Loading/Loadingh";
 
interface Category {
    name: string;
    imgSrc: string[];
    imgSrcW: string[];
}

const categories: Category[] = [
  { 
    name: "ياغورت ممزوج",
    imgSrc: [yagurt,yagurtFr],
    imgSrcW:[yagurtW,yagurtFrW]
  },
  { 
    name: "الألبان",
    imgSrc: [cahier,cahierFr],
    imgSrcW: [cahierW,cahierFrW]
  },
  { 
    name: "ياغورت للشرب",
    imgSrc: [boire,boireFr],
    imgSrcW: [boireW,boireFrW]
  },
  { 
    name: "حليب طازج",
    imgSrc: [milk,milkFr],
    imgSrcW: [milkW,milkFrW]
  },
 
  
];

// const points:Point[] = [
//   {
//     id:"1",
//     name:"leksar",
//     phone:"7070789",
//     position:[18.078514,-15.970219]
//   },
//   {
//     id:"2",
//     name:"carafor",
//     phone:"7070789",
//     position:[18.178514,-15.990219]
//   },
// ]
const PageHome = () => {
   
  const [distributions,setDistributions]=useState<Distribution[]>([])
  const { produits}= useTraductionContext()
  const {t}=useTranslation()
  const [isLoading,setIsLoading] = useState(true)
  
  useEffect(() => {
     Promise.all([
       apiClient.get('/distribution'), 
    ])
      .then(([distributionResponse]) => {
          setDistributions(distributionResponse.data);
          setIsLoading(false)
       })
      .catch((error) => console.error(error));
  }, []);

 
  return (
    isLoading?
    <Loadingh/>:
    <div className="nc-PageHome relative overflow-hidden">
       <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
         <SectionHero3  />
        
             {/* SECTION 1 */}
          <div className="relative py-16">
              <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
              <SectionSliderNewCategories
                produits={produits}
                itemPerRow={4}
                heading={(t('heading'))}
                subHeading={(t('subHeading'))}
                sliderStyle="style2"
                uniqueClassName="PageHome_s2"
              />
          </div>
          <div className="relative h-auto p-4 ">
          <BackgroundSection className="bg-red-50 dark:bg-black dark:bg-opacity-20 " />
            <div className=" flex justify-center ">
              <Heading desc={t("descDistribtion")}>
                {t('distrub')}
              </Heading>
            </div>
            <div className=" flex justify-center ">
             <MapComponent
                points={distributions}
                zoom={12}
              />
            </div>
           </div>
          <div className="relative py-16">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div>
          

            <SectionVideos />

      </div> 
      <Footer />
    </div>
   );
};


export default React.memo(PageHome);
