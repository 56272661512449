import { ChangeEvent, InputHTMLAttributes } from "react";

export interface RadioOption {
  label: string;
  value: string;
}

interface Props  {
  label: string;
  name: string;
  options: RadioOption[];
  value:string
  space?: string;
  error?: Record<string, string>;
  onChange:(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)=>void
}

const RadioField: React.FC<Props> = ({ label, name, options,value, error, space ,onChange}) => {
  return (
    <div className={`mb-4 ${space}`}>
      <span className="block text-sm font-medium text-gray-700">{label}</span>
      {options.map((option, index) => (
        <label key={index} className="block mt-2 text-sm">
          <input
            type="radio"
            name={name}
            checked={value===option.value}
            value={option.value}
            className="mr-2 text-green-600 focus:ring-green-500"
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
      {error && error[name] && <p className="text-red-500 text-sm mt-1">{error[name]}</p>}
    </div>
  );
};

export default RadioField;
