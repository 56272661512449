import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageLogin from "containers/PageLogin/PageLogin";
import useWindowSize from "hooks/useWindowResize";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import SiteHeader from "containers/SiteHeader";
import PagePanier from "containers/PagePanier/PagePanier";
import { RefreshProvider } from "components/Refresh";
import PageShop from "containers/PageShop/PageShop";
import PageFAQ from "containers/PageFAQ/PageFAQ";
import HealthPage from "containers/HealthPage/HealthPage";
 import { TraductionProvider } from "components/TraductionContext";
 import { EventProvider } from "components/EventContext";
import BlogSingle from "containers/BlogPage/BlogSingle";

const Historique = lazy(() => import("containers/Dashboard/Historique"));
const ProduitPage = lazy(() => import("containers/Dashboard/ProduitPage"));
const CommandPage = lazy(() => import("containers/Dashboard/CommandPage"));
const StatistiquePage = lazy(() => import("containers/Dashboard/StatistiquePage"));
const AccountPass = lazy(() => import("containers/Dashboard/AccountPass"));
const DistributionPage = lazy(() => import("containers/Dashboard/DistributionPage"));
const TraductionPage = lazy(() => import("containers/Dashboard/TraductionPage"));
const ParametrePage = lazy(() => import("containers/Dashboard/ParametrePage"));
const MessagePage = lazy(() => import("containers/Dashboard/MessagePage"));
const  EvenementPage= lazy(() => import("containers/Dashboard/EvenementPage"));
const  BlogPage= lazy(() => import("containers/BlogPage/BlogPage"));
 
export const pages: Page[] = [
  { path: "/#", exact: true, component: PageHome },
  { path: "/#", exact: false, component: Page404 },
  { path: "/", exact: true, component: PageHome },
  { path: "/home", exact: true, component: PageHome },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
   //
   { path: "/blog-single", component: BlogSingle },
   //
  { path: "/show", component: ListingStayDetailPage },
  { path: "/panier", component: PagePanier },
  { path: "/shop", component: PageShop },
  { path: "/FAQ", component: PageFAQ },
  { path: "/health", component: HealthPage },
  { path: "/login", component: PageLogin },

   
];
export const pagesLazy: Page[] = [
   
  { path: "/historique", exact: true, component: Historique},
  { path: "/produits", component: ProduitPage },
  { path: "/commandes", component: CommandPage },
  { path: "/statistics", component: StatistiquePage },
  { path: "/account-password", component: AccountPass },
  { path: "/distribution", component: DistributionPage },
  { path: "/traduction", component: TraductionPage },
  { path: "/parametre", component: ParametrePage },
  { path: "/message", component: MessagePage },
  { path: "/evenement", component: EvenementPage },
  { path: "/blog", component: BlogPage },

   
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter basename={""}>
      <ScrollToTop />
      <RefreshProvider>
        <TraductionProvider>
        <EventProvider>
          <SiteHeader/>
        
          <Routes>

          {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}

          {pagesLazy.map(({ component, path }) => {
              const Component = component;
                return (
                     <Route key={path} element={
                      <Suspense>
                        <Component />
                      </Suspense>
                     
                    } path={path} />
                 ); 
            })}

            <Route key={'/page404'} element={<Page404 />} path={'/page404'} />
            <Route key={'*'} element={<Page404 />} path={'*'} /> {/* Cette ligne gère toutes les routes non trouvées */}
          </Routes>
        </EventProvider>
      </TraductionProvider>
    </RefreshProvider>

      {/* {WIN_WIDTH < 768 && <FooterNav />} */}
      
    </BrowserRouter>
  );
};

export default MyRoutes;
