import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalReserveMobile from "./ModalReserveMobile";
import InputField from "Personal/InputField";
import AddProductForm from "./AddProductForm";
import { Produit } from "model/models";
export interface Props {
  produit:Produit
}

const MobileFooterSticky: FC< Props> = ({ produit}) => {
 
  
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <AddProductForm produit={produit}/>
    </div>
  );
};

export default MobileFooterSticky;
