import { FC,  useEffect,  useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos"; 
import { useLocation } from "react-router-dom";
import { Produit, Traduction } from "model/models";
import MobileFooterSticky from "./MobileFooterSticky";
import AddProductForm from "./AddProductForm";
import apiClient from "utils/apiClient";
import StayCard from "components/StayCard/StayCard";
import { useTranslation } from "react-i18next";
import { useTraductionContext } from "components/TraductionContext";
 
export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
    const [isOpen, setIsOpen] = useState(false);
     const {traductions}  = useTraductionContext();
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const location = useLocation();
    const produit:Produit=location.state
    const {id, description, image, nom } = produit;
    const {i18n}=useTranslation()
    const {produits}=useTraductionContext()
    const lang= i18n.language
    const nomTr=traductions.find(trad=>trad.cle === id+'.t' )
    const descTr=traductions.find(trad=>trad.cle === id+'.d' )
    let nomUsed=(nomTr && lang==='fr') ? nomTr.fr:nom
    let descUsed=(descTr && lang==='fr') ? descTr.fr:description
    const impp=`data:${image.contentType};base64,${image.contents}`
     
  const PHOTOS: string[]  =[impp]
 
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
   
  const handleCloseModal = () => {
    setIsOpen(false)
   };  
   
    
const renderSection2 = () => {
    return (
    <div className="  mt-5 w-full">
        <h2 className="text-3xl font-semibold">{nomUsed} </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
            

            <div className="text-justify">
                <span className="text-xl font-semibold">
                    {descUsed} 
                </span>
            </div>
             
            </div>
    </div>
    );
};
const renderSidebar = () => {
    
    return (
    <div className="shadow-x mt-4 ml-4 mr-4 ">
      <AddProductForm produit={produit} />
    </div>
    );
};

  return ( 
    <div
      className={`  ${className}`}
     >
      <>
        <header className="">
          <div className="flex ">
            <div
              className=" w-1/2  hidden lg:block  cursor-pointer mt-15 mr-10 ml-10" 
            >
              <NcImage
                containerClassName=""
                className="border-4 border-black-500   rounded-3xl object-cover w-3/4 h-full rounded-md sm:rounded-xl "
                src={PHOTOS[0]}
                onClick={() => handleOpenModal(0)}
              />
               <div className=" w-full   space-y-8 lg:space-y-10 lg:pr-10  ">
                {renderSection2()}
                {renderSidebar()}
              </div>
            </div>
            <div
              className=" w-full  block lg:hidden cursor-pointer mr-10 ml-10"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName=""
                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                src={PHOTOS[0]}
              />
              <div className=" w-full   space-y-8 lg:space-y-10 lg:pr-10  ">
                {renderSection2()}
                
                <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
               </div>
            </div>
            <div className="w-1/8"></div>
            <div className=" hidden lg:block flex-grow  w-1/3">
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2 ml-10 mr-10">
                  {produits.map((produit) => (
                      <StayCard size="default" className="shadow-2xl" key={produit.id} produit={produit} />
                      // <CardCategory1 taxonomy={produit}/>
                  ))}
              </div>
             
            </div>
           
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

       <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
       {/* <div className="top-28">
                {renderSection2()}
              </div>
             
              <div className="  top-28">
                {renderSidebar()}
              </div> */}
        {!isPreviewMode && <MobileFooterSticky produit={produit} />}
      </main> 
      

    </div>
   );
};

export default ListingStayDetailPage;
