import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export function LangueSwicher() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const dir = lang === 'ar' ? 'rtl' : 'ltr';

 
  useEffect(() => {
    i18n.on('languageChanged', setLang);
    return () => {
      i18n.off('languageChanged', setLang);
    };
  }, [i18n]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang, dir }} />
      {lang === 'fr' && <Button className='text-black dark:text-white' onClick={() => changeLanguage('ar')}>Arabe</Button>}
      {lang === 'ar' && <Button className='text-black dark:text-white' onClick={() => changeLanguage('fr')}>الفرنسية</Button>}
    </>
  );
}
 