import React, { FC  } from "react";
import { useTranslation } from "react-i18next";
import twFocusClass from "utils/twFocusClass";

export interface NextPrevProps {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  onlyNext?: boolean;
  onlyPrev?: boolean;
}

const NextPrev: FC<NextPrevProps> = ({
  className = "",
  onClickNext = () => {},
  onClickPrev = () => {},
  btnClassName = "w-10 h-10",
  onlyNext = false,
  onlyPrev = false,
}) => {
  const {i18n}=useTranslation()
  const renderNext=()=>{
    return<>
    {!onlyNext && (
      <button
        className={`${btnClassName} ${
          !onlyPrev ? "mr-[6px]" : ""
        } bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
        onClick={onClickPrev}
        title="Prev"
        data-glide-dir="<"
      >
        <i className="las la-angle-left"></i>
      </button>
    )}</>
  }
  const renderPreven=()=>{
    return<>
    {!onlyPrev && (
      <button
        className={`${btnClassName} bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
        onClick={onClickNext}
        title="Next"
        data-glide-dir=">"
      >
        <i className="las la-angle-right"></i>
      </button>
    )}</>
  }
  return (
    <div
      className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 ${className}`}
      data-nc-id="NextPrev"
      data-glide-el="controls"
    >
     {i18n.language==="ar"?<>
      { renderPreven()}
      { renderNext()}
     </>: <>
      { renderNext()}
      { renderPreven()}
      </>}
    </div>
  );
};

export default NextPrev;
