import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { useTraductionContext } from "components/TraductionContext";

export interface SocialsList1Props {
  className?: string;
}

 

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const {parametre}=useTraductionContext()    
  const {facebook,instagrame,youtube,twitter} =parametre
  const socialsDemo: SocialType[] = [];

 
  // Vérification et ajout des réseaux sociaux non vides
  if (facebook) {
      socialsDemo.push({ name: "Facebook", icon: "lab la-facebook-square", href: facebook });
  }
  if (twitter) {
      socialsDemo.push({ name: "Twitter", icon: "lab la-twitter", href: twitter });
  }
  if (youtube) {
      socialsDemo.push({ name: "Youtube", icon: "lab la-youtube", href: youtube });
  }
  if (instagrame) {
      socialsDemo.push({ name: "Instagram", icon: "lab la-instagram", href: instagrame });
  } 

  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socialsDemo.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
