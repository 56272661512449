import { useTranslation } from "react-i18next";
import Accordion from "./Accordion";
import Heading from "components/Heading/Heading";
import Footer from "shared/Footer/Footer";

const FAQs = [
    {
      summary: "What does camel milk taste like?",
      details: "The most common answer we receive is, “Oh, it tastes like milk!” It does not have the amount of sugar that other milks have, so it doesn’t taste as sweet. It has a smooth, slightly salty, taste. The consistency of the milk is similar to a 2% fat milk."
    },
    {
      summary: "Why is camel milk so expensive?",
      details: "Instead of comparing camel milk to cow milk, it is more like comparing camel milk to your entire vitamin cabinet! The nutritional value is so high, it should be thought of more like a vitamin or supplement. Camels also produce less than half the amount of milk that cows produce in a day, and there are far less camels in the US compared to the number of cows. At Camel Culture, we work hard to keep our prices lower than our competition in order to provide our customers with the best quality and lowest priced camel milk in the US. You also only need to consume between 3-8 oz of camel milk a day to get all the nutritional benefits! Read our full blog article about the price of camel milk."
    },
    {
      summary: "Should I buy camel milk powder or the liquid camel milk?",
      details: "It's a matter of personal preference! Both the powder and liquid camel milk have an incredibly high nutritional value. The powder is nice because it has a very long shelf-life, and it's easily mixed with smoothies, protein shakes, coffee, muffins, mac n cheese, etc. But there's no substitute for the smooth, fresh taste of liquid milk."
    },
    {
      summary: "Do you sell raw camel milk?",
      details: "No, raw camel milk cannot legally be shipped across US state lines. Our milk is flash pasteurized, which heats the milk to 150 degrees for 15 seconds and then cooled. It allows for the nutrients and taste to stay while removing the bad bacteria. Our milk is never ultra pasteurized."
    },
    {
      summary: "What is the shelf-life of camel milk?",
      details: "When kept frozen, the shelf life is 6 months. When it is refrigerated, the shelf lasts about 3-4 weeks. If refrozen after it has been thawed, the shelf life is cut down to 5-7 days once you thaw it out a second time."
    },
    {
      summary: "Why are there layers of separate when the milk has been sitting for a while?",
      details: "Because our milk is all-natural! Fat molecules in milk want to gather together. When they do that, they form a layer of cream at the top of a container of milk. Simply shake well before consuming. That kind of separation in milk can make people uneasy, it might look like something was wrong with the milk like it has curdled, but it’s not the same thing. We want our milk to be as pure as possible without all the processing so all of our milk is non-homogenized. Homogenization is the process that allows the milk and the fat to combine. So next time you drink the milk, give it a good shake before consuming."
    },
    {
      summary: "Do you sell in retail stores or wholesale?",
      details: "Yes, we sell to retail stores nationwide! We would love to stock the shelves at your store. Please contact info@camelculture.org"
    },
    {
      summary: "Can I cook or bake with camel milk?",
      details: "Yes! Camel milk is excellent to use for cooking and baking!"
    }
  ];
  
  const FAQsFr = [
    {
      summary : "Qu'est-ce que le lait d'Emechtil?",
      details: "Emechtil est un lait de chamelles naturel pris des chamelles qui paissent sur des herbes et des arbres naturels, avec les soins nécessaires pour garantir la sécurité du produit jusqu'à ce qu'il atteigne son objectif (le consommateur) sans aucun changement dans ses caractéristiques physiques et chimiques et sans aucun agent de conservation, ce qui en fait un médicament tout en étant un aliment."
    },  
    {
      summary : "Pourquoi ce nom ?",
      details: "Ce lait a été nommé d'après la région d'Emechtil, qui est une extension naturelle de l'Aloukar, située au centre de cinq wilayas du pays : Inchiri, Adrar, Tagant, Brakna et Trarza, cette dernière étant la plus répandue et la plus proche, ce qui en a fait un choix naturel pour implanter le projet, en plus de la présence importante de troupeaux de chameaux. La région se distingue par ses plantes et arbres sains, qui constituent leur alimentation et contribuent à la formation du lait de chamelles sur lequel elles paissent."
    },
    {
      summary: "Pourquoi ne pasteurisons-nous pas notre produit ?",
      details: "La pasteurisation consiste à chauffer le lait à 75 degrés Celsius pendant quelques secondes, puis à le refroidir brusquement pour éliminer certains micro-organismes nocifs et prolonger la durée de conservation de deux à sept jours dans de bonnes conditions de réfrigération. Emechtil est un lait de chamelles quotidien naturel sans conservateurs. Étant quotidien, nous avons préféré ne pas le traiter thermiquement afin de préserver ses valeurs nutritionnelles complètes, telles que les vitamines, les minéraux et les anticorps, qui pourraient être perdus lorsqu'ils sont traités thermiquement. Nous envisageons d'acquérir une unité de pasteurisation éclair qui préserve les caractéristiques naturelles du lait, bien que son coût reste élevé par rapport à la pasteurisation normale."
    },
    {
      summary: "Où trouver Emechtil ?",
      details: "Au début, nous le distribuions à certains des éleveurs que nous avons sélectionnés selon des critères spécifiques, à un prix leur laissant une marge bénéficiaire pour les encourager à le commercialiser. Mais nous avons remarqué que certains de ces éleveurs n'avaient pas de réfrigération suffisante pour maintenir le produit dans l'état où nous l'avions laissé, ce qui nous a amenés à envisager des alternatives pour y remédier. Nous avons donc ouvert des points de vente pour les produits, où nous garantissons la qualité de notre produit, dans différents endroits de la capitale adaptés à la modeste quantité distribuée. Nous avons également initié un programme pour employer les jeunes chômeurs en leur donnant un magasin entièrement équipé avec une marge bénéficiaire leur assurant un salaire décent en échange de leur service dans le magasin."
    },
  
    {
      summary: "Emechtil propose-t-il d'autres produits ?",
      details: "Étant donné que la durée de conservation du lait réfrigéré est de deux à trois jours, nous avons divisé cette période de sorte que le lait soit consommé le premier jour, puis le reste est retiré le deuxième jour et passé pendant quelques minutes sur le 'sikoua el hamra', bien connu de nos ancêtres, pour obtenir le babeurre qui peut être bu immédiatement après les repas ou mélangé avec de l'eau pour former de l'azirit après l'ajout d'un peu de sucre pour ceux qui le souhaitent. Nous avons également utilisé une partie du babeurre pour produire un autre produit appelé 'moussa' à base d'une substance sans calories appréciée par les enfants et adaptée aux diabétiques. Ce babeurre restant est ensuite mis dans une baratte pour séparer le beurre, ce qui donne un produit que nous avons appelé 'beurre de chamelles', que nous avons emballé dans des boîtes de 75 grammes et dont la vente est limitée aux points de vente en raison de la faible quantité produite. Nous signalons également que nous prévoyons de produire un produit important pour améliorer la valeur nutritionnelle du lait en mélangeant des dattes avec lui, appelé lait aux dattes nourrissant et savoureux, et nous allons pasteuriser ce produit en raison de la présence de dattes."
    },
  
    {
      summary: "Les prix des produits sont-ils fixes ?",
      details: "Le prix du produit est fixe, que ce soit dans les points de vente ou chez les éleveurs, et il est de 100 nouvelles ouguiyas par litre, que ce soit pour du lait ou du babeurre, et de 50 nouvelles ouguiyas pour un demi-litre de lait ou de babeurre. Le prix du lait aux dattes est également de 50 nouvelles ouguiyas. Nous soulignons que nous avons régulé certains des éleveurs qui vendaient à un prix plus élevé que celui spécifié en leur retirant l'avantage de la distribution."
    },
    {
      summary: "Quelles sont les lacunes et les perspectives ?",
      details: "Nous aspirons à ce qu'Emechtil devienne un modèle à suivre à tous égards en établissant une usine utilisant les dernières technologies pour produire des produits concurrentiels sur le marché mondial. Nous avons donc élaboré des plans à court, moyen et long terme. Avant cela, nous travaillerons à la création d'une ferme pour soutenir le régime alimentaire des chameaux, leur offrant des soins de santé complets sans avoir à renoncer aux herbes et aux arbres naturels qui sont essentiels pour améliorer la valeur nutritionnelle et thérapeutique du lait. Et avant tout cela, nous aspirons à posséder des véhicules tout-terrain réfrigérés capables de se rendre chez les éleveurs de chameaux où qu'ils se trouvent."
    },
  
    {
      summary : "Quel goût a le lait de chamelles ?",
      details : " La réponse la plus courante que nous recevons est : \"Oh, ça a le goût du lait ! » Il ne contient pas autant de sucre que les autres laits, il n'a donc pas un goût aussi sucré. Il a un goût doux et légèrement salé. La consistance du lait est similaire à celle d'un lait à 2 % de matière grasse."
    }, 
  ];
  const FAQsAr = [

    {
      summary : "ما هو حليب أمشتيل؟",
      details: "أمشتيل هو حليب إبل طبيعي مأخوذ من نوق ترعى على الأعشاب والأشجار الطبيعية مع الرعاية اللازمة لضمان سلامة المنتج حتى يصل إلى هدفه (المستهلك) دون أي تغيير في خصائصه الفيزيائية والكيميائية ودون أي مواد حافظة، مما يجعله دواءً مع كونه غذاءً."
    },  
    {
      summary: "لماذا هذا الاسم؟",
      details: "سمي هذا الحليب باسم منطقة أمشتيل التي هي امتداد طبيعي لألوكار والذي يتوسط خمس ولايات من الوطن هي: إنشيري، أدرار، تكانت، لبراكنة واترارزة وتعتبر الأخيرة الأكبر انتشارا والأقرب الشيء الذي جعلنا نختارها لمقام المشروع بالإضافة إلى التواجد الكبير لعدد كبير من قطعان الإبل. وتتميز المنطقة بنباتاتها وأشجارها الصحية كغذاء لها والمفيدة في تكوين حليب الإبل الذي ترعى عليها."
    },
  
    {
      summary: "لماذا لا نبستر منتجنا؟",
      details: "عملية البسترة تتضمن تسخين الحليب إلى 75 درجة مئوية لبضع ثوانٍ ثم تبريده بشكل مفاجئ للقضاء على بعض البكتيريا الضارة وتمديد فترة الحفظ من يومين إلى سبعة أيام في ظروف تبريد جيدة. حليب أمشتيل هو حليب يومي طبيعي خالٍ من المواد الحافظة، ونظرًا لطبيعته اليومية، فقد تجنبنا تعريضه للحرارة للحفاظ على قيمته الغذائية الكاملة، مثل الفيتامينات والمعادن والأجسام المضادة، التي قد تفقدها عند المعالجة الحرارية. نفكر في الاستحواذ على وحدة بسترة فورية تحافظ على الخصائص الطبيعية للحليب على الرغم من أن تكلفتها مرتفعة مقارنة بالبسترة العادية."
    },
  
    {
      summary: "أين يمكن العثور على حليب أمشتيل؟",
      details: "في البداية، كنا نوزعه على بعض المجمعات الذين اخترناهم وفقًا لمعايير محددة، بسعر يترك لهم ربحًا يشجعهم على التسويق لكننا لاحظنا أن بعض هؤلاء المربين لا يتوفرون على تبريد كافٍ للحفاظ على المنتج في الحالة التي تركناه عليها، مما دفعنا إلى التفكير في بدائل لحل هذه المشكلة. لذا قمنا بفتح نقاط بيع للمنتج، حيث نضمن جودة منتجنا، في أماكن مختلفة من العاصمة تناسب الكمية المتواضعة الموزعة. كما قمنا أيضًا بتنفيذ مبادرة لتوظيف الشباب العاطلين عن العمل عن طريق توفير محل تجهيز كامل لكل منهم مع ترك هامش ربح يضمن له راتبًا مقبولًا مقابل خدمته في المحل."
    },
  
    {
      summary: "هل يقدم أمشتيل منتجات أخرى؟",
      details: "نعم لأمشتيل مجموعة من المنتجات المنتوعة و نسعى دائما لتطويرها و تنويعها."
    },
  
    {
      summary: "هل أسعار المنتجات ثابتة؟",
      details: "سعر المنتج ثابت سواء في نقاط البيع أو عند المجمعات."
    },
    
    {
      summary: "ما هو طعم حليب الإبل؟",
      details: "الإجابة الأكثر شيوعًا التي نتلقاها هي:   لا يحتوي على كمية السكر الموجودة في أنواع الحليب الأخرى، لذلك لا يكون طعمه حلوًا. له طعم ناعم ومالح قليلاً. قوام الحليب يشبه الحليب الذي يحتوي على 2٪ دسم."
    }, 
     
  ];
 const PageFAQ = () => { 
    const {t,i18n}=useTranslation()
    const FAQs = i18n.language==="ar"?FAQsAr:FAQsFr
  return (
    <div className="">
        <div className="flex flex-col items-center">
            <Heading desc className="mt-12 ml-10">
                {t('FAQTitle')}
            </Heading>
            <div className="border-4 w-12 border-blue-200"/> 
        </div>

        <div className="w-full max-w-md mx-auto">
            <Heading desc className="mt-12" textSize="text-xl md:text-2xl">
                {t('FAQGeneral')}
            </Heading>        
            <div className="border-2 w-12 border-blue-200"/> 
        </div>
        <div className=" mb-20">
        {FAQs.map(faq=>
            <Accordion
                summary={faq.summary}
                details={faq.details}
             />
        )}
        </div>
        <Footer />
     </div>
  );
};

export default PageFAQ;
