import React, { useEffect, useState } from "react";
import apiClient from "utils/apiClient"; 
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Produit, Traduction } from "model/models";
import StayCard from "components/StayCard/StayCard"; 
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
 import { useTranslation } from "react-i18next";
import Heading from "components/Heading/Heading";
import Footer from "shared/Footer/Footer";
import { useTraductionContext } from "components/TraductionContext";

interface Category {
    name: string;
    imgSrc: string[];
    imgSrcW: string[];
}

const PageShop = () => {
    

    const {t}=useTranslation()
    const {traductions,produits}=useTraductionContext()
     
   
   
  return (
    <div className="nc-PageHome relative overflow-hidden">

        <BackgroundSection className="bg-blue-50 dark:bg-black dark:bg-opacity-20 " />
        <div className="flex flex-col items-center">
            <Heading desc className="mt-12 ml-10">
                {t('shop')}
            </Heading>
            <div className="border-4 w-12 border-blue-200"/> 
        </div>
        <div className="flex justify-center">
        <div className="mt-12  grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2 w-2/3 mb-10">

            {produits.map((produit) => (
                <StayCard size="small" className="shadow-2xl" key={produit.id} produit={produit}/>
                // <CardCategory1 taxonomy={produit}/>
            ))}
        </div>

        </div>
        <Footer />
    </div>
   );
};


export default React.memo(PageShop);
