import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
 import { Evenement, Traduction } from "model/models";
import { useTranslation } from "react-i18next";
import icon1 from "images/amechtil/calendar.png"
import icon2 from "images/amechtil/icon.png"
export interface CardCategory3Props {
   post: Evenement;
   traductions:Traduction[],

}

const CardCategory3: FC<CardCategory3Props> = ({
   post,
   traductions
}) => {
  const {id, image, nom, date,lieu   } = post;
  const impp=`data:${image.contentType};base64,${image.contents}`
   const trN = traductions.find((traduction) => traduction.cle === "EventT"+id) ;
  const trD = traductions.find((traduction) => traduction.cle === "EventC"+id) ;
   
  const {i18n} =  useTranslation()

  const lang=i18n.language
  const nomtr = lang==="ar"? nom:trN?.fr
 
  return (
    <Link
    to="/blog-single" state={post} 
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage src={impp}  
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {nomtr}
        </h2>
        <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1 flex items-center space-x-2">
              <img src={icon1} alt="Date Icon" className="h-5 w-5 text-neutral-500 dark:text-neutral-400"/>
              <span>{date.date.slice(0,10)}</span>
              <img src={icon2} alt="Location Icon" className="h-5 w-5 text-neutral-500 dark:text-neutral-400"/>
              <span>{lieu}</span>
            </span>
      </div>
    </Link>
  );
};

export default CardCategory3;
