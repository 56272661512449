import React, { FC, useEffect, useState } from "react";
import { ajouterAuPanier } from "utils/localStorage";
import { Produit } from "model/models";
import InputField from "Personal/InputField";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useRefresh } from "components/Refresh";
import Alert from "shared/Alert/Alert";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export interface AddProductFormProps {
   produit:Produit
}

const AddProductForm: FC<AddProductFormProps> = ({ produit}) => {
    const [quantit,setQuantit]=useState<number| undefined>(1)
    const [showAlert, setShowAlert] = useState(false);

    const { refresh, setRefresh } = useRefresh();
    const {t}=useTranslation()
    const { state } = useLocation();

      useEffect(() => {
         window.scrollTo(0, 0);
      }, [state]);
    const handelAddPruduct = ()=>{
      if (quantit) {
        ajouterAuPanier({
            produit:produit,
            quantite:quantit
        })
        setRefresh(prev => !prev)
        setQuantit(1)
        setShowAlert(prev => !prev);
      }
         
        
      } 
      
      const handeChange = (event: React.ChangeEvent<HTMLInputElement> )=>{
            const value = event.target.value;
            const val = parseInt(value)
            if (!(val<0) ) {
              setQuantit(val)
            }
             
            
      }
      
  return ( 
    <div className="flex items-center">
        <div className="flex-grow   w-1/7">
        <span className="text-base font-extrabold text-red-400">
             <span className="text-6xl font-extrabold text-red-400">
               {produit.prix}
             </span>  
              <span className="text-xxs mr-3 font-extrabold text-red-400">
                {t('ouguiya')}
              </span> 
      </span>
        </div>
        <div className="flex-grow justify-start w-3/7">
        <InputField  onChange={handeChange} label={t('quantite')} name='quantite' type='number' value={quantit || ''} placeholder={t('quantite')+' ...'}/>
        </div>
        <div className="flex  justify-end  mr-3 w-3/7 ">
          <div className="">
            <div className="h-10">
        <ButtonPrimary onClick={handelAddPruduct} className="rounded-lg ml-2 "> {t('ajoutM')}</ButtonPrimary>
        </div></div></div>
        <Alert type="info" isOpen={showAlert}>
          {t("okAdd")}
        </Alert>
     </div>
     
  );
};

export default AddProductForm;
