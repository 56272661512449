import { Parametre, Produit, Traduction } from 'model/models';
import React, { createContext, useState, ReactNode, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import apiClient from 'utils/apiClient';

type TraductionContextType = {
    traductions: Traduction[];
    parametre: Parametre
    produits: Produit[]
   };
  
  // Créez un nouveau contexte avec une valeur par défaut
  const TraductionContext = createContext<TraductionContextType | null>(null);
  const parametre0: Parametre = {
    id: 0,
    phone: '',
    email: '',
    facebook: '',
    twitter: '',
    instagrame: '',
    youtube: '',
    Adresse: '',
    aboutTitle: '',
    aboutContent: ''
  };
export const TraductionProvider = ({ children }: { children: ReactNode }) => {
  const [traductions, setTraductions] = useState<Traduction[]>([]);
  const [parametre, setParametre] = useState<Parametre>(parametre0);
  const [produits,setProduits]=useState<Produit[]>([])

  useEffect(() => {
    Promise.all([
      apiClient.get("/traduction"),
      apiClient.get('/parametre'), 
      apiClient.get('/produit'), 
   ])
     .then(([response1,response2,produitResponse]) => {
       setTraductions(response1.data);
        setParametre(response2.data);
        setProduits(produitResponse.data);
      })
     .catch((error) => console.error(error));
 }, []);

  return (
    <TraductionContext.Provider value={{ traductions,parametre,produits }}>
      {children}
    </TraductionContext.Provider>
  );
};
export const useTraductionContext = () => {
    const context = useContext(TraductionContext);
    if (context === null) {
      throw new Error('useTraductionContext must be used within a TraductionProvider');
    }
    return context;
  };
  