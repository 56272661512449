import React, { useEffect, useState } from 'react';

interface AlertProps {
  type?: "danger" | "warning" | "info" | "success";
  isOpen: boolean;
  children: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type, isOpen, children }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
       if(isOpen){
          setShowAlert(true);
          const timer = setTimeout(() => {
            setShowAlert(false);
          }, 2000); 

           return () => {
            clearTimeout(timer);
          };
        }
   }, [isOpen]);

  if (!showAlert) return null;

  const alertColors = {
    danger: 'text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400',
    warning: 'text-yellow-800 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300',
    info: 'text-blue-800 bg-blue-50 dark:bg-gray-800 dark:text-blue-400',
    success: 'text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400',
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
      className={`p-4 mb-4 text-sm rounded-lg ${alertColors[type || 'info']}`}
      role="alert"
    >
      {children}
    </div>
  );
};

export default Alert;
