import React from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
 import { useTranslation } from "react-i18next";

function Navigation() {
  const { t } = useTranslation();
    const  NavItemType1:NavItemType ={
      id: '1',
      name: t('h1'),
      href: '/contact',
      targetBlank: false,
   }
     const  NavItemType7:NavItemType ={
      id: '7',
      name: t('h7'),
      href: '/about',
      targetBlank: false,
   }

    const NavItemType2: NavItemType = {
      id: '2',
      name: t('h2'),
      href: '/about',
      type:"dropdown",
      children:[NavItemType1],
      targetBlank: false,
    }
  
  
    const NavItemType6: NavItemType = {
      id: '6',
      name: t('h6'),
      href: '/FAQ',
      targetBlank: false,
    }
    const NavItemType4: NavItemType = {
      id: '4',
      name: t('h4'),
      href: '/shop',
      targetBlank: false,
    }
    const NavItemType5: NavItemType = {
      id: '5',
      name: t('h5'),
      href: '/health',
      targetBlank: false,
    }
    const NavItemType3: NavItemType = {
      id: '3',
      name: t('h3'),
      href: '/health',
      type:"dropdown",
      children:[NavItemType5],
      targetBlank: false,
    }
    const NavItemType8: NavItemType = {
      id: '8',
      name: t('h8'),
      href: '/blog', 
      targetBlank: false,
    }
  const data = [NavItemType4,NavItemType3,NavItemType2,NavItemType8,NavItemType6]

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {data.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
