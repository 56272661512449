import axios from 'axios';
 

//cle api :

const apiClient = axios.create({
  baseURL: "https://emechtil-mpe.com/public/index.php/",
  headers: {
    Accept: 'application/json, text/plain, */*, multipart/form-data',
  }
});
     
// const apiClientTrans = axios.create({
//   baseURL: "http://127.0.0.1:8000/",
//   headers: {
  //
//     'content-type': 'application/json',
//     'X-RapidAPI-Key': 'c9170fb4e2msha74083e6f8e71c2p1781f2jsn7b41ce8d25bd',
//     'X-RapidAPI-Host': 'google-translator9.p.rapidapi.com'
//   }
// });

export function setAuthToken(token: string) {
  if (token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common['Authorization'];
  }
}
// export const translationWord= (text: string,targ :string) =>{ 
//   const source= targ==='ar'? 'fr':'ar'
//   const target=targ==='ar'? 'ar':'fr'
   
//   const res =   apiClientTrans.post("https://google-translator9.p.rapidapi.com/v2", {
//  		q: text,
// 		source: source,
// 		target: target,
//     format: 'text'
//     })
//     return res||''; 
   
//  }

 
export default apiClient;

