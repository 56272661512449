import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
 import Header from "components/Header/Header";
import {
  ShoppingBagIcon as ShoppingCartIcon,
  Cog8ToothIcon as CogIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
 import { PathName } from "routers/types";

export type SiteHeaders = "Header 1" | "Header 2" | "Header 3";

interface HomePageItem {
  name: string;
  slug: PathName;
}

let OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};
let OBSERVER: IntersectionObserver | null = null;
 

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  let [headers] = React.useState<SiteHeaders[]>([
    "Header 1",
     
  ]);

   
   
  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
  };

  
   
  const renderHeader = () => {
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";
     
    return <Header className={headerClassName} navType="MainNav1" />;
  };

  return (
    <>
      <Helmet>
        <title>Emechtil</title>
      </Helmet>
      {renderHeader()}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
