import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import distLogo from "images/amechtil/distribution.png";
import userLocationPic from "images/amechtil/userPP.png";
import { Distribution } from 'model/models';
import { useTranslation } from 'react-i18next';
import { haversine } from 'utils/map';

type MapProps = {
  points: Distribution[];
  zoom: number;
};

const MapComponent: React.FC<MapProps> = ({ points, zoom }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<L.Map | null>(null);
  const {t} = useTranslation()
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const btt:[number, number] | null =[17.487218, -14.564592]
 
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude]);
      },
      (error) => {
        console.error("Erreur lors de la récupération de la position de l'utilisateur", error);
      }
    );
  }, []);
 
  
  useEffect(() => {
    if (mapRef.current) {
      if (!mapInstance.current) {
        if (userLocation) {
          mapInstance.current = L.map(mapRef.current).setView(userLocation, zoom);

        }else{
          mapInstance.current = L.map(mapRef.current).setView([18.079806, -15.965226], zoom);
        }
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
            '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(mapInstance.current);
        
        const markerIcon = L.icon({
          iconUrl: distLogo,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        });
        const markerIcon2 = L.icon({
          iconUrl: userLocationPic,
          iconSize: [15, 20],
          iconAnchor: [12, 20],
        });
       
        if (userLocation) {
          const markerUser = mapInstance.current && L.marker([userLocation[0],userLocation[1]], { icon: markerIcon2 }).addTo(mapInstance.current);
          markerUser?.bindPopup(`<b>${t('LOCALUSER')}</b>`);
          const closestPoints = points.sort((a, b) => 
            haversine(userLocation[0], userLocation[1], parseFloat(a.lat), parseFloat(a.lng)) -
            haversine(userLocation[0], userLocation[1], parseFloat(b.lat), parseFloat(b.lng))
          ).slice(0, 2);
    
          // Dessiner une ligne rouge entre l'utilisateur et chaque point
          closestPoints.forEach(point => {
            if (mapInstance.current) {
              const polyline = L.polyline([userLocation, [parseFloat(point.lat), parseFloat(point.lng)]], { color: 'red' }).addTo(mapInstance.current);
              const distance = haversine(userLocation[0], userLocation[1], parseFloat(point.lat), parseFloat(point.lng));
              polyline.bindPopup(`${t("LADISTANCEEST")} ${distance.toFixed(2)} ${t("KM")}`);
            }
          });
        }
        
        points.forEach(point => {
           const marker = mapInstance.current && L.marker([parseFloat(point.lat),parseFloat(point.lng)], { icon: markerIcon }).addTo(mapInstance.current);
          marker?.bindPopup(`<b>${point.name}</b><br>${point.phone}`);
        });
      } else {
        mapInstance.current.setView([18.079806, -15.965226], zoom);
      }
    }

    // Cleanup on unmount
    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
      }
    };
  }, [points,t,userLocation]);

  return <div ref={mapRef} className='h-[400px] w-screen md:w-3/4'/>;
};

export default MapComponent;
