import rightImg from "images/amechtil/buro.jpg";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import CommonLayout from "containers/CommonLayout";
import { useTranslation } from "react-i18next";
import Footer from "shared/Footer/Footer";
import { useTraductionContext } from "components/TraductionContext";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const {t,i18n}=useTranslation()    
  const isLeft = i18n.language=='ar'?false:true
  const {parametre,traductions}=useTraductionContext()    
  const titleTr= traductions.find(trad=>trad.cle === 'aboutT' )
  const ContenueTr= traductions.find(trad=>trad.cle === 'aboutC' )
   const titleTrU=i18n.language=='ar'?titleTr?.ar:titleTr?.fr
  const contenueTrU=i18n.language=='ar'?ContenueTr?.ar:ContenueTr?.fr
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>{t("INTRO")}</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading={titleTrU}
          btnText=""
          isLeft={isLeft}
          subHeading={contenueTrU||''}
        />

      </div>
      <Footer />
    </div>
  );
};

export default React.memo(PageAbout);
