import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importez vos fichiers de traduction
import fr from 'traduction/fr.json';
import ar from 'traduction/ar.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "ar",
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fr: {
        translation: fr, // Utilisez vos traductions françaises importées
      },
      ar: {
        translation: ar, // Utilisez vos traductions arabes importées
      },
    },
  });

export default i18n;
