import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "containers/CommonLayout";
import { useTranslation } from "react-i18next";
import Footer from "shared/Footer/Footer";
import { useTraductionContext } from "components/TraductionContext";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import { Message } from "model/models";
import apiClient from "utils/apiClient";
  
export interface PageContactProps {
  className?: string;
}



const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [message,setMessage]= useState<Message>({
    id:0,
    name:'',
    email:'',
    message:''
  })

  const {t,i18n}=useTranslation()    
  const {parametre}=useTraductionContext()    
  const {facebook,instagrame,youtube,twitter} =parametre
  const socialsDemo: SocialType[] = [];

  const [errors, setErrors] = useState<Record<string, string>>({});

  // Vérification et ajout des réseaux sociaux non vides
  if (facebook) {
      socialsDemo.push({ name: "Facebook", icon: "lab la-facebook-square", href: facebook });
  }
  if (twitter) {
      socialsDemo.push({ name: "Twitter", icon: "lab la-twitter", href: twitter });
  }
  if (youtube) {
      socialsDemo.push({ name: "Youtube", icon: "lab la-youtube", href: youtube });
  }
  if (instagrame) {
      socialsDemo.push({ name: "Instagram", icon: "lab la-instagram", href: instagrame });
  }  
  const info = [
    {
      title: t("ADDRESS"),
      desc: parametre.Adresse,
    },
    {
      title: t("EMAIL"),
      desc: parametre.email,
    },
    {
      title: t("PHONE"),
      desc: parametre.phone,
    },
  ];
  const onSubmit=async()=>{
    try {
      await apiClient.post('/message/new', message);
      setMessage({
        id:0,
        name:'',
        email:'',
        message:''
      })
     } catch (error) {
     }
  }
  const handleAddMessage =  (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors: Record<string, string> = {};

    if (message.name.trim() === '') {
      validationErrors.name = 'الاسم إجباري';
    }
    if (message.email.trim() === '') {
      validationErrors.email = 'الإميل إجباري';
    }
    if (message.message.trim() === '') {
      validationErrors.message = 'الرسالة فارغة';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
   onSubmit()
  };
  
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    
    const { name, value } = event.target;
    setMessage((prev ) => ({
      ...prev ,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',  
    }));
  };
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>{t("Call_us")} </title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
           {t("Call_us")} 
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {t("SOCIAL_MEDIA")}
                </h3>
                <SocialsList className="mt-2" socials={socialsDemo} />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleAddMessage}>
                <label className="block">
                  <Label> {t("FULL_NAME")}</Label>

                  <Input
                    placeholder= {t("NAME_AND_SURNAME")}
                    type="text"
                    className="mt-1"
                    name="name"
                    value={message.name}
                    onChange={handleChange}
                  />
                  { errors["name"] && <p className="text-red-500 text-sm mt-1">{errors["name"]}</p>}

                </label>
                <label className="block">
                  <Label>{t("EMAIL")}</Label>

                  <Input
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={message.email}
                    onChange={handleChange}
                  />
                  { errors["email"] && <p className="text-red-500 text-sm mt-1">{errors["email"]}</p>}

                </label>
                <label className="block">
                  <Label>{t("MESSAGE")}</Label>

                  <Textarea name="message" className="mt-1" rows={6} 
                   value={message.message}
                   onChange={handleChange}
                 />
                 { errors["message"] && <p className="text-red-500 text-sm mt-1">{errors["message"]}</p>}

                </label>
                <div>
                  <ButtonPrimary type="submit"   > {t("SEND_MESSAGE")}</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(PageContact);
