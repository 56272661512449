import React, { useState } from 'react';

interface AccordionProps {
  summary: string;
  details: string;
}

const Accordion: React.FC<AccordionProps> = ({ summary, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full max-w-md mx-auto text-justify">
      <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div className=" mt-5">
          <div className="flex items-start">
           
            <div className="ml-3 w-0 flex-1 p-2">
              <p className="text-sm font-medium text-gray-900">
                {summary}
              </p>
            </div> 
            <div className="flex-shrink-0 p-2">
              {/* Icon */}
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-400">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-400">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className=" ">
            <div className=" p-5">
              <p className="text-lg text-gray-500">
                {details}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
