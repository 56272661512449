import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'my_app_token';

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getUserIdFromToken(): number | null {
  const token = getToken();
  if (token) {
    const decodedToken: any = jwtDecode(token);
    return decodedToken.id;
  }
  return null;
}

export function isTokenExpired(): boolean {
  const token = getToken();
  if (token) {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000; // convert to seconds
    return decodedToken.exp < currentTime;
  }
  return true; // token not found or expired
}


