import { ItemPanier } from "model/models";

export function setLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  export function getLocalStorage(key: string): any {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  }

  export function removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  export function storeImageInLocalStorage(file: File, key: string) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLocalStorage(key, reader.result);
    };
  }

  export function rERC5b4PoR51qWEvAWuJsX6yRVRBvRVta7(key: string) {
    const imageData = getLocalStorage(key);
    if (imageData) {
      return imageData
    }
    return null;
  }

  export function ajouterAuPanier(article: ItemPanier) {
    let panier: ItemPanier[] = getLocalStorage('panier');

    if (!panier) {
        panier = []; // Si le panier n'existe pas, créez un nouveau tableau
    }

    // Vérifiez si l'article est déjà dans le panier
    const articleExistant = panier.find(item => item.produit.id === article.produit.id);

    if (articleExistant) {
        // Si l'article existe déjà dans le panier, mettez à jour la quantité
        articleExistant.quantite += article.quantite;
    } else {
        // Sinon, ajoutez l'article au panier
        panier.push(article);
    }

    setLocalStorage('panier', panier); // Enregistrez le panier dans le localStorage
}

// Fonction pour retourner le contenu du panier
export function obtenirContenuPanier(): ItemPanier[] | null {
    return getLocalStorage('panier');
}

// Fonction pour vider le panier
export function viderPanier() {
    removeLocalStorage('panier');
}

// Fonction pour supprimer un article du panier par sa clé
export function supprimerArticleDuPanier(produitId: number) {
    let panier: ItemPanier[] = getLocalStorage('panier');

    if (panier) {
        panier = panier.filter(item => item.produit.id !== produitId);
        setLocalStorage('panier', panier); // Enregistrez le panier mis à jour dans le localStorage
    }
}