import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Produit, Traduction } from "model/models"; // Importer l'interface Produit
import NcImage from "shared/NcImage/NcImage"; 
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useTraductionContext } from "components/TraductionContext";

export interface StayCardProps {
  className?: string;
  produit: Produit;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  produit,
}) => {
  
  const {  id, prix, description, image, nom} = produit;
  const {i18n}=useTranslation()
  const lang= i18n.language
  const { traductions}= useTraductionContext()

  const nomTr=traductions.find(trad=>trad.cle === id+'.t' )
  const descTr=traductions.find(trad=>trad.cle === id+'.d' )
  let nomUsed=(nomTr && lang==='fr') ? nomTr.fr:nom
  let descUsed=(descTr && lang==='fr') ? descTr.fr:description

  const impp=`data:${image.contentType};base64,${image.contents}`
  
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        
        <div className={`block aspect-w-4 aspect-h-3`}>
            <NcImage src={impp} /> 
        </div>
       
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
       
        <div className="space-y-2">
          <span className="text-lg text-blue-600 font-bold dark:text-neutral-400">
            {nomUsed} {/* Mettre à jour les détails de l'produit */}
          </span>
          <div className="flex items-center space-x-2">
            {/* Badge ADS */}
            <h2
              className={`font-medium capitalize text-sm `}
            >
              <span className="line-clamp-1">{descUsed}</span>
            </h2>
          </div>
           
        </div>
        {/* Ligne de séparation */}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        {/* Prix */}
        <div className="flex justify-between items-center">
          <span className="text-base font-extrabold text-red-400">
             <span className="text-2xl font-extrabold text-red-400"> {prix}</span>  <span className="text-xxs mr-3 font-extrabold text-red-400">{t('ouguiya')}</span> 
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
      
    >
       
        <Link to="/show" state={produit}>
          {renderSliderGallery()}
          {renderContent()}  
        </Link>
      
    </div>
  );
};

export default StayCard;
