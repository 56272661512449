import MyRouter from "routers/index";
function App() {

  return (
    <div className="font-cairo bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 ">
      <MyRouter />
    </div>
  );
}

export default App;
