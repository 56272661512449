import { useState, type FC, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.scss';
import apiClient from 'utils/apiClient';
import { Command } from 'model/models';
import ButtonSuccess from 'shared/Button/ButtonSuccess';
import InputField from 'Personal/InputField';
import { obtenirContenuPanier, viderPanier } from 'utils/localStorage';
import { useRefresh } from 'components/Refresh';
import Alert from 'shared/Alert/Alert';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import SelectField from 'Personal/SelectField';
import NcImage from 'shared/NcImage/NcImage';
import bankily from "images/amechtil/bankily.png"
import masrivi from "images/amechtil/masrivi.jpeg"
import sadad from "images/amechtil/sadad.png"
import amanti from "images/amechtil/emanty.png"
import { useTraductionContext } from 'components/TraductionContext';
import RadioField, { RadioOption } from 'Personal/RadioField';

interface Props {
    isOpenre:boolean;
    handleCloseModal: () => void;
    onSubmit: ( data:Command) => void;
    refresh:boolean
}
const command0:Command={
    nomClient: "",
    telephone: "", 
    outilPaiement: "1", 
    items:[],
    image:{
      contents:'',
      contentType:''
    },
    date:new Date(),
}
  
const types:RadioOption[]=[
  {
  value: "1",
  label: "الدفع عند الإستلام"
  },
  {
  value: "2",
  label: "الدفع عبر الانترنت"
  },
    
] 
const types2 =[
  {
    value: "1",
    label:"Paiement à la livraison"
  },
  {
    value: "2",
    label: "Paiement en ligne"
  },
   
] 
const ReservationForm: FC<Props> = ({isOpenre,handleCloseModal ,onSubmit,refresh}) => {
    const [reservationData, setReservationData] = useState<Command>(command0);
    const [errors, setErrors] = useState<Record<string, string>>({});
     const [showAlert, setShowAlert] = useState(false);
     const {t,i18n}=useTranslation()    
     const {parametre}=useTraductionContext()    

    useEffect(() => {
      const contenu = obtenirContenuPanier();
      contenu &&  setReservationData((prevSite) => ({
        ...prevSite,
        items: contenu,
      }));
    
    }, [ ]);
    
     
      const handleSubmit = ( ) => {
    
        const validationErrors: Record<string, string> = {};
        if (reservationData.nomClient.trim() === '') {
          validationErrors.nomClient = t("CHAMPNAME");
        }
        if (reservationData.telephone.trim() === '') {
          validationErrors.telephone = t('CHAMPTEL');
        }
        if (reservationData.outilPaiement === '2') {
          if (reservationData.image?.contents.trim() === '') {
          validationErrors.imgConfirmation = t('CHAMIMAGE');
          }
        }
         
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length === 0) {
            const val =  reservationData.outilPaiement==='1'?'Kash':'Bankily'
            
              onSubmit({
                  nomClient: reservationData.nomClient,
                  telephone: reservationData.telephone, 
                  outilPaiement: val, 
                  items:[],
                  image:reservationData.image,
                  date:new Date(),
              })

              setReservationData(command0);
              setShowAlert(prev => !prev) 
              handleCloseModal()
               
        }
      };

      const handleChange = (
        event:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLSelectElement> ) => {
        const { name, value } = event.target;
        
        setReservationData(prevData => ({
          ...prevData,
          [name]: value
        }));
      };
       
    return (
        <Modal
            isOpen={isOpenre}
            onRequestClose={handleCloseModal}
            contentLabel="Reservation"
            className="modal"
            overlayClassName="modal-overlay"
            ariaHideApp={false}
        > 
        <Alert type="info" isOpen={showAlert}>
            {t("ORDER_PROCESSED")}
         </Alert>
         <h2 className="text-xl font-bold text-center mb-4">{t("PLACE_ORDER")} </h2>

        <button className="close" onClick={handleCloseModal} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
  
            <InputField key={1} error={errors } label={t("FULL_NAME")}  name='nomClient' type='text' value={reservationData.nomClient} onChange={handleChange}/>
            <InputField key={2}error={errors } label={t("PHONE")}  name='telephone' type='text' value={reservationData.telephone} onChange={handleChange}/>             
            <RadioField options={i18n.language==='ar'?types:types2}  label={t("MOYPAY")} name='outilPaiement'value={reservationData.outilPaiement||'1'} onChange={handleChange} />
      <div className="flex justify-center">
        <h2>   {t("PNIERMSG")}</h2>
       </div>
       {(reservationData.outilPaiement===  "2") &&
          <>
            <div className="flex justify-center mt-5">
              <NcImage src={bankily} className="w-12 ml-3 border-2"/> 
              <NcImage src={masrivi} className="w-12  ml-3 border-2" />
              <NcImage src={sadad} className="w-12  ml-3 border-2" />
              <NcImage src={amanti} className="w-12  ml-3 border-2" />
            </div>
            <div className="flex justify-center mt-3">
              <h2> {parametre.phone}</h2>
            </div>
            <InputField key={3} error={errors } label={t("PAYMENT_PROCESS_IMAGE")}   accept="image/*" name='imgConfirmation' type='file' 
             onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                  setReservationData((prevOperator) => ({
                    ...prevOperator,
                      image: {
                        contents: e.target?.result as string,
                        contentType: file.type,
                        fichier: file
                      }
                  })
                  )}
                  reader.readAsDataURL(file);
                }}}
                />
            </>}
         
            <ButtonPrimary onClick={handleSubmit}>{t("ORDER_CONFIRMATION")} </ButtonPrimary>

          
     </Modal>
    );
}

export default ReservationForm ;
