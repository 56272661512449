import React, { FC, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, Navigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import apiClient, { setAuthToken } from "utils/apiClient";
import { setToken } from "utils/tokenJWT";
import Loading2 from "components/Loading/Loading2";

export interface PageLoginProps {
  className?: string;
}


const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [newtoken, setNewtoken] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});


  const usernameRef=useRef<HTMLInputElement>(null)
  const passwordRef=useRef<HTMLInputElement>(null)

  const  handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

  const validationErrors: Record<string, string> = {};

    if (!usernameRef.current?.value) {
      validationErrors.username = 'Email address is required';
    }
    if (!passwordRef.current?.value) {
      validationErrors.password = 'Password is required';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (usernameRef.current && passwordRef.current) {
      const username=usernameRef.current.value
      const password=passwordRef.current.value
      try {
        setIsLoading(true);
        const response = await apiClient.post('/api/login', {username:username,password:password},
        {headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }});
        const token = response.data.token;

        setAuthToken(token);
        setToken(token);
        setNewtoken(token);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        validationErrors.error = 'Le username ou  le mot de passe fourni sont incorrects. Veuillez vérifier vos identifiants et réessayer'
        setErrors(validationErrors);
        setIsLoading(false);
      }
    }
  };
  if (newtoken) {
    return <Navigate to={'/commandes'}/>
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
        {errors.error && <span className="text-red-500">{errors.error}</span>}
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit} action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                 type="email"
                 placeholder="example@example.com"
                 className="mt-1"
                 ref={usernameRef}
                 name="username"
              />
                {errors.username && <span className="text-red-500">{errors.username}</span>}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input ref={passwordRef} type="password" className="mt-1"  name="password" />
              {errors.password && <span className="text-red-500">{errors.password}</span>}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
             
          </form>

          {isLoading && <Loading2 />}

        </div>
      </div>
    </div>
  );
};

export default PageLogin;
