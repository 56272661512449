import React, { createContext, useState, ReactNode, Dispatch, SetStateAction, useContext } from 'react';

type RefreshContextType = {
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
  };
  
  // Créez un nouveau contexte avec une valeur par défaut
  const RefreshContext = createContext<RefreshContextType | null>(null);

export const RefreshProvider = ({ children }: { children: ReactNode }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <RefreshContext.Provider value={{ refresh, setRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
};
export const useRefresh = () => {
    const context = useContext(RefreshContext);
    if (context === null) {
      throw new Error('useRefresh must be used within a RefreshProvider');
    }
    return context;
  };
  