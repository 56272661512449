import React, { FC, useState, useEffect, useMemo } from "react";
import image1 from "images/amechtil/buro7.jpg";
import image2 from "images/amechtil/buro4.jpg";
import image3 from "images/amechtil/buro3.jpg";
import { useTranslation } from "react-i18next";
import NextPrev from "shared/NextPrev/NextPrev"; 
import { Link, Navigate, useNavigate } from "react-router-dom";

export interface SectionHero3Props {
  className?: string;
}
 
const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  const { t,i18n } = useTranslation();
  const lang=i18n.language
  const classH= lang=='ar'?  
        'text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%]':
        'text-1xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl !leading-[115%]'
  
  const images = [image1, image2, image3];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage(images[(images.indexOf(currentImage) + 1) % images.length]);
    }, 5000);
    return () => clearInterval(timer);
  }, [currentImage, images]);

 
  const firstRender = () => {
    return (
      <>
        <h2 className="text-md sm:text-3xl">{t('NON_ALLERGENE').toUpperCase()}</h2>
        <div className="border-2 w-10 border-purple-50" />
        <p className="text-sm sm:text-xl">{t('NON_ALLERGENE_DESCRIPTION')}</p>
        <p className="text-sm sm:text-xl">{t('NON_ALLERGENE_PROTEINE_1')}</p>
        <p className="text-sm sm:text-xl">{t('NON_ALLERGENE_PROTEINE_2')}</p>
      </>
    );
  };
  
  const secondRender = () => {
    return (
      <>
        <h2 className="text-md sm:text-3xl">{t('PRODUITS_LAITIERS_PLUS_SAINS').toUpperCase()}</h2>
        <div className="border-2 w-10 border-purple-50" />
        <p className="text-sm sm:text-xl">{t('PRODUITS_LAITIERS_PLUS_SAINS_LACTOSE')}</p>
        <p className="text-sm sm:text-xl">{t('PRODUITS_LAITIERS_PLUS_SAINS_IMMUNITE')}</p>
        <p className="text-sm sm:text-xl">{t('PRODUITS_LAITIERS_PLUS_SAINS_PROBIOTIQUES')}</p>
      </>
    );
  };
  
  const thirdRender = () => {
    return (
      <>
        <h2 className="text-md sm:text-3xl">{t('LAIT_QUI_DONNE_LA_VIE').toUpperCase()}</h2>
        <div className="border-2 w-10 border-purple-50" />
        <p className="text-sm sm:text-xl">{t('LAIT_QUI_DONNE_LA_VIE_DESCRIPTION_1')}</p>
        <p className="text-sm sm:text-xl">{t('LAIT_QUI_DONNE_LA_VIE_DESCRIPTION_2')}</p>
      </>
    );
  };
  
   const onNext=()=>{
    setCurrentImage(images[(images.indexOf(currentImage) + 1) % images.length])
   }
   const onPrevent=()=>{
    if (images.indexOf(currentImage)==0) {
      setCurrentImage(images[2])
    }else{
      setCurrentImage(images[(images.indexOf(currentImage) - 1)])
    }
   }
   const handleNavigation = () => {
    // Utilisez navigate pour aller à '/shop'
    navigate('/shop');
  };
  
  return (
    <div
      className={` relative mr-0 ml-0 ${className}`}
     >
      <div className="absolute z-10 text-white bg-opacity-80 inset-x-0 top-[40%] p-4 space-y-5  ">
           {currentImage===images[0] && firstRender()}
           {currentImage===images[1] && secondRender()}
           {currentImage===images[2] && thirdRender()}
            
  
      </div>
      <div className="absolute z-10   inset-x-0 bottom-[1%]   p-4 space-y-5 rounded-lg  ">
           <NextPrev 
              onClickNext={ onNext}
              onClickPrev={onPrevent}
              className="justify-center mt-16" 
            />
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover "
          src={currentImage}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
