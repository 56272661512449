import { Evenement, Parametre, Produit, Traduction } from 'model/models';
import React, { createContext, useState, ReactNode, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import apiClient from 'utils/apiClient';

type EventContextType = {
    events: Evenement[];
    setEvents: Dispatch<SetStateAction<Evenement[]>>;
   };
  
  // Créez un nouveau contexte avec une valeur par défaut
  const EventContext = createContext<EventContextType | null>(null);
   
export const EventProvider = ({ children }: { children: ReactNode }) => {
  const [events, setEvents] = useState<Evenement[]>([]);
   
  return (
    <EventContext.Provider value={{  events,setEvents }}>
      {children}
    </EventContext.Provider>
  );
};
export const useEventContext = () => {
    const context = useContext(EventContext);
    if (context === null) {
      throw new Error('useEventContext must be used within a EventProvider');
    }
    return context;
  };
  