import React, { InputHTMLAttributes } from 'react'

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
    space?:string;
    error?: Record<string, string>
  }


const InputField: React.FC<InputFieldProps> = ({ label, name,space,error,...args
}) => {
    return (
    <div className={`mb-4 ${space}`}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        name={name} 
        id={name}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
        {...args}
      />
      {error && error[name] && <p className="text-red-500 text-sm mt-1">{error[name]}</p>}

   </div>
  )
}

export default InputField