import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation"; 
import MenuBar from "shared/MenuBar/MenuBar";
import panierD from "images/app/panierD.png"
import panierL from "images/app/panierL.png"
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { obtenirContenuPanier } from "utils/localStorage";
import { useRefresh } from "components/Refresh";
import { LangueSwicher } from "./LangueSwicher";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { refresh, setRefresh } = useRefresh();
  let nbrContenu = obtenirContenuPanier()?.length;
    useEffect(() => {
       
   }, [refresh]);
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="  md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className=" md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
          <LangueSwicher/>
            <SwitchDarkMode />
            <div className="px-0.5" />
            <Link to="/panier" className="relative">
              {nbrContenu!==0 && nbrContenu && (
                <span className="absolute top-0 right-2 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                  {nbrContenu}
                </span>
              )}
              <NcImage src={panierD} alt="panier-Dark" className="hidden max-h-10 dark:block"/>
              <NcImage src={panierL}  alt="panier-Light" className="block max-h-10  dark:hidden"    />
            </Link>
          </div>
          <div className="flex lg:hidden justify-end ">
            <LangueSwicher/>
            <SwitchDarkMode />
            <div className="px-0.5" />
            <Link to="/panier" className="relative">
              {nbrContenu!==0 && nbrContenu && (
                <span className="absolute top-0 right-2 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                  {nbrContenu}
                </span>
              )}
              <NcImage src={panierD} alt="panier-Dark" className="hidden max-h-10  dark:block"/>
              <NcImage src={panierL}  alt="panier-Light" className="block max-h-10  dark:hidden"    />
            </Link>
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
