import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
 import CardCategory3 from "components/CardCategory3/CardCategory3";
 import NextPrev from "shared/NextPrev/NextPrev";
 import useNcId from "hooks/useNcId";
import { Produit, Traduction } from "model/models";
import { useTranslation } from "react-i18next";
import { useTraductionContext } from "components/TraductionContext";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  produits:Produit[],
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  produits,
   itemPerRow = 5,
   sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS =  "SectionSliderNewCategories__" + uniqueClassName + useNcId();
    const { i18n } = useTranslation(); // get the i18n instance
    const direct =  'rtl'
    const { traductions}= useTraductionContext()

  let MY_GLIDEJS = useMemo(() => {
    return i18n.language==="ar"?
     new Glide(`.${UNIQUE_CLASS}`, {
      direction: direct,
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    }):
      new Glide(`.${UNIQUE_CLASS}`, {
       perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 1000);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

    
  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {produits.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {<CardCategory3 produit={item} traductions={traductions} />}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
