import React from "react";

const Loading2 = () => {
  return (
    <div className="flex items-center justify-center">
      <style>
        {`
          @keyframes dot {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
          .dot:nth-child(1) {
            animation: dot 0.6s infinite;
          }
          .dot:nth-child(2) {
            animation: dot 0.6s infinite 0.2s;
          }
          .dot:nth-child(3) {
            animation: dot 0.6s infinite 0.4s;
          }
        `}
      </style>
      <div className="flex">
        <div className="w-3 h-3 rounded-full mr-1 dot" style={{ backgroundColor: "red" }}></div>
        <div className="w-3 h-3 rounded-full mr-1 dot" style={{ backgroundColor: "green" }}></div>
        <div className="w-3 h-3 rounded-full mr-1 dot" style={{ backgroundColor: "red" }}></div>
      </div>
    </div>
  );
};

export default Loading2;
