  import React, { useEffect } from "react";
import {  useLocation } from "react-router-dom";
 import NcImage from "shared/NcImage/NcImage";
  import { Helmet } from "react-helmet";
import { Evenement } from "model/models";
import icon1 from "images/amechtil/calendar.png"
import icon2 from "images/amechtil/icon.png"
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "./SectionSliderNewCategories";
import { useEventContext } from "components/EventContext";
import { useTranslation } from "react-i18next";
import { useTraductionContext } from "components/TraductionContext";


const BlogSingle = () => { 
  // const location = useLocation();
  const { t } = useTranslation(); // get the i18n instance
const { state } = useLocation();
  const post:Evenement=state
  const { nom,date,description,id,image, lieu} = post;
  const {events}=useEventContext()
  const img=`data:${  image?.contentType};base64,${ image?.contents}`
  window.scrollTo(0, 0);

  const {traductions}=useTraductionContext()
  const trN = traductions.find((traduction) => traduction.cle === "EventT"+id) ;
  const trD = traductions.find((traduction) => traduction.cle === "EventC"+id) ;
   
  const {i18n} =  useTranslation()

  const lang=i18n.language
  const nomtr = lang==="ar"? nom:trN?.fr
  const desctr = lang==="ar"? description:trD?.fr
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
           <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {nomtr}
           </h1>
          
        </div>
        
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          {desctr} 
        </p>
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1 flex items-center space-x-2">
              <img src={icon1} alt="Date Icon" className="h-5 w-5 text-neutral-500 dark:text-neutral-400"/>
              <span>{date.date.slice(0,10)}</span>
              <img src={icon2} alt="Location Icon" className="h-5 w-5 text-neutral-500 dark:text-neutral-400"/>
              <span>{lieu}</span>
            </span>
         
           
      </div>
    );
  };

  
  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{nom}</title>
      </Helmet>
      {renderHeader()}
       <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={img}
      />

      <div className="nc-SingleContent container space-y-10 text-justify">
        {renderContent()}
         <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
         </div> 
         <div className="relative py-16">
              <BackgroundSection className="bg-blue-50 dark:bg-black dark:bg-opacity-20 " />
              <SectionSliderNewCategories
                posts={events}
                itemPerRow={4}
                heading={t("h8")}
                subHeading={""}
                sliderStyle="style2"
                uniqueClassName="PageHome_s2"
              />
          </div>
    </div>
  );
};

export default BlogSingle;
