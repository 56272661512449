 import { useTranslation } from "react-i18next"; 
import NcImage from "shared/NcImage/NcImage";
 import whyMilk from "images/amechtil/whyMilk.png"
 import whyMilkFr from "images/amechtil/whyMilkFr.png"
import Heading from "components/Heading/Heading";
import SectionVideos from "containers/PageHome/SectionVideos";
import Footer from "shared/Footer/Footer";
 const HealthPage = () => { 
    const {t,i18n}=useTranslation()    
    
   return (
    <div className="nc-PageHome relative overflow-hidden">
      {i18n.language==='ar'?
         <NcImage src={whyMilk} className="ml-10 mr-10 mt-20"/>:
         <NcImage src={whyMilkFr} className="ml-10 mr-10 mt-20"/>
         
      }  
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">   
         <div className="flex flex-col items-center">
            <Heading desc className="mt-12 ml-10">
                {t('CAMEL_MILK_WHY')}
            </Heading>
            <div className="border-4 w-12 border-blue-200"/>             
            <div className="w-full max-w-md mx-auto mt-5 mb-5 text-center">
               <p>{t('Camel_milk_is11')}</p>
               <p>{t('Camel_milk_is12')}</p>
               <p>{t('Camel_milk_is13')}</p>
               <p>{t('Camel_milk_is14')}</p>
            </div>
            <Heading desc className="mt-12 ml-10">
                {t('CAMEL_MILK_BENEFITS')}
            </Heading>
            <div className="border-4 w-12 border-blue-200"/>             
            <div className="w-full max-w-md mx-auto mt-5 mb-5 text-center">
               <p>{t('Camel_milk_is')}</p>
               <p>{t('Camel_milk_is2')}</p>
            </div>
            <Heading desc className="mt-12 ml-10">
               {t('CAMEL_MILK_IS')} {/* Titre */}
            </Heading>
            <div className="border-4 w-12 border-blue-200"/> {/* Barre de séparation */}
            <div className="w-full max-w-md mx-auto mt-5 mb-5">
               <p>{t('CAMEL_MILK_IS1')}</p> {/* Contenu - Point 1 */}
               <p>{t('CAMEL_MILK_IS2')}</p> {/* Contenu - Point 2 */}
               <p>{t('CAMEL_MILK_IS3')}</p> {/* Contenu - Point 3 */}
               <p>{t('CAMEL_MILK_IS4')}</p> {/* Contenu - Point 4 */}
               <p>{t('CAMEL_MILK_IS5')}</p> {/* Contenu - Point 5 */}
               <p>{t('CAMEL_MILK_IS6')}</p> {/* Contenu - Point 6 */}
               <p>{t('CAMEL_MILK_IS7')}</p> {/* Contenu - Point 7 */}
               <p>{t('CAMEL_MILK_IS8')}</p> {/* Contenu - Point 8 */}
               <p>{t('CAMEL_MILK_IS9')}</p> {/* Contenu - Point 9 */}
               <p>{t('CAMEL_MILK_IS10')}</p> {/* Contenu - Point 10 */}
               <p>{t('CAMEL_MILK_IS11')}</p> {/* Contenu - Point 11 */}
               <p>{t('CAMEL_MILK_IS12')}</p> {/* Contenu - Point 12 */}
            </div>

        </div>
        <SectionVideos isCenter/>
      </div> 
      <Footer />
     </div>
  );
};

export default HealthPage;
