import { Command, ItemPanier } from "model/models";
import React, { useEffect, useState } from "react";
import { obtenirContenuPanier, supprimerArticleDuPanier, viderPanier } from "utils/localStorage";
 import ReservationForm from "./ReservationFrom";
import { useRefresh } from "components/Refresh";
import apiClient from "utils/apiClient";

import { useTranslation } from "react-i18next";
import { useTraductionContext } from "components/TraductionContext";
import { useNavigate } from "react-router-dom";

const PagePanier = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [panier, setPanier] = useState<ItemPanier[]>([]);
  const { refresh, setRefresh } = useRefresh();
  const { traductions} = useTraductionContext(); 
  const navigate = useNavigate();
  const handleCloseModal = () =>  setIsOpen(false)
  const handleOpenModal = () =>  setIsOpen(true)
  const {t,i18n}=useTranslation()    
   const lang= i18n.language

   
   useEffect(() => {
    const contenu = obtenirContenuPanier();
    contenu ? setPanier(contenu):setPanier([]); 
   }, [refresh ]);
 
  const handleReservation = async(reservationData:Command) => { 
        
    const itemsv = {items:panier.map(item => ({ quantite: item.quantite, produit: item.produit.id }))};
     if (panier.length!=0) {
       const formData = new FormData();
        formData.append('nomClient', ""+reservationData.nomClient);
        formData.append('telephone', ""+reservationData.telephone);
        formData.append('outilPaiement', ""+reservationData.outilPaiement);
        if (reservationData.image?.fichier) {
          formData.append('image', reservationData.image.fichier); 
        }
      
      
      try {
        const response = await apiClient.post(`/commande/new`, formData);
          const id=response.data.id
          try {
            const response2 = await apiClient.post(`/commande/addItems/${id}`, itemsv);
            viderPanier()
            navigate('/shop');

            setRefresh(prec=>!prec)
            
          } catch (error) { 
          }
      } catch (error) {
        }
     }
   
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const val=parseInt(value)
    if(val>=0){
    const index = parseInt(event.target.name)
    const newPanier = [...panier];
    newPanier[index].quantite = val;
    
    setPanier(newPanier);}
  };
  const rendreItemPanier=(item: ItemPanier,index:number)=>{
  const {id,nom}= item.produit 
  const nomTr=traductions.find(trad=>trad.cle === id+'.t' )
   let nomUsed=(nomTr && lang==='fr') ? nomTr.fr:nom
    return(
      <div key={"b"+index}>
          <div  className="border-t-2 mb-4"></div>
          <div  className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <img src={`data:${item.produit.image.contentType};base64,${item.produit.image.contents}`} className="w-16 h-16 rounded-md mr-3 ml-3" />
              <div>
                <h2 className="font-bold">{nomUsed}</h2>
                 <input type="number" name={ ""+index}value={panier[index].quantite} onChange={handleChange }
                  className="border rounded-md px-2 py-1 mt-2"
                />
                <button onClick={()=>{supprimerArticleDuPanier (item.produit.id); setRefresh(prev=>!prev)}} className="text-red-500 font-bold mt-2">{t("DELETE")}</button>
              </div>
            </div>
            <div className="text-right">
              <p className="font-bold"> {item.produit.prix} {t("ouguiya")} </p>
            </div>
          </div>
        </div>
    )
  }

  return (

    <div className="container mx-auto mt-8  p-4 w-4/5">
      
      
      <h1 className="text-lg sm:text-2xl font-bold mb-4"> {t("YOUR_SHOPPING_CART")}</h1>
      <h2 className="text-lg flex justify-end mb-2">{t("PRICE")}</h2>
      {panier.map((item, index) => (
        rendreItemPanier(item,index)
      ))}
      <div className="border-t-2 mb-4"></div>
      <div className="flex justify-end">
        <p className="font-bold">{t("TOTAL")}: {panier.reduce((acc, item,index) => acc + item.produit.prix * item.quantite, 0)} {t("ouguiya")} </p>
      </div>
      <br/> 
      <br/> 
      <div className="fixed bottom-0 left-0 right-0   dark:bg-blend-darken p-4 flex justify-between border-t border-gray-300">
        <p className="font-bold text-black dark:text-white">{ t("TOTAL")} : {panier.reduce((acc, item,index) => acc + item.produit.prix *  item.quantite, 0)} {t("ouguiya")} </p>
        {panier.length!==0 && <button onClick={handleOpenModal} className="bg-blue-500 text-white px-4 py-2 rounded-md">  {t("PLACE_ORDER")}</button>}  
      </div> 
          <ReservationForm handleCloseModal={handleCloseModal} isOpenre={isOpen} refresh={refresh} onSubmit={handleReservation} />
    </div>
  );
};

export default React.memo(PagePanier);
