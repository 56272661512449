import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { NavItemType } from "shared/Navigation/NavigationItem";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
      { href: "#", label: "Prototyping" },
      { href: "#", label: "Design systems" },
      { href: "#", label: "Pricing" },
      { href: "#", label: "Security" },
    ],
  },
];

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const  NavItemType1:NavItemType ={
    id: '1',
    name: t('h1'),
    href: '/contact',
    targetBlank: false,
 }
   const  NavItemType7:NavItemType ={
    id: '7',
    name: t('h7'),
    href: '/about',
    targetBlank: false,
 }

  const NavItemType2: NavItemType = {
    id: '2',
    name: t('h2'),
    href: '/about',
    type:"dropdown",
    children:[NavItemType1],
    targetBlank: false,
  }


  const NavItemType6: NavItemType = {
    id: '6',
    name: t('h6'),
    href: '/FAQ',
    targetBlank: false,
  }
  const NavItemType4: NavItemType = {
    id: '4',
    name: t('h4'),
    href: '/shop',
    targetBlank: false,
  }
  const NavItemType5: NavItemType = {
    id: '5',
    name: t('h5'),
    href: '/health',
    targetBlank: false,
  }
  const NavItemType3: NavItemType = {
    id: '3',
    name: t('h3'),
    href: '/health',
    type:"dropdown",
    children:[NavItemType5],
    targetBlank: false,
  }
  const NavItemType8: NavItemType = {
    id: '8',
    name: t('h8'),
    href: '/blog', 
    targetBlank: false,
  }
  const data = [NavItemType4,NavItemType3,NavItemType2,NavItemType8,NavItemType6]
  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive ? "text-secondary" : ""
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
          >
            {item.name}
          </span>
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1 "
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-3 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
         <div className="text-sm"> 
          <h2 className="font-semibold text-neutral-700 px-7 dark:text-neutral-200">
            {t("MENUE")}
          </h2>

          <ul className="flex flex-col py-2 space-y-0">
            {data.map(_renderItem)}
          </ul>
        </div>
        <div className="text-sm ml-20"> 
          <h2 className="font-semibold text-neutral-700 px-3 dark:text-neutral-200">
            -----
          </h2>

          
          <ul className="flex flex-col py-2 space-y-0">
            <div className="text-justify">
                {t("FOOTERCONTENT")}
            </div>
          </ul>

        </div>
      </div>
    </div>
  );
};

export default Footer;
