import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import { Produit, Traduction } from "model/models";
import { useTranslation } from "react-i18next";

export interface CardCategory3Props {
   produit: Produit;
   traductions:Traduction[],

}

const CardCategory3: FC<CardCategory3Props> = ({
   produit,
   traductions
}) => {
  const {id, image, nom, prix   } = produit;
  const impp=`data:${image.contentType};base64,${image.contents}`
  const {t,i18n}=useTranslation()
  const lang= i18n.language
  const nomTr=traductions.find(trad=>trad.cle === id+'.t' )
  let nomUsed=nom
  nomUsed=(nomTr && lang==='fr') ? nomTr.fr:nom

  return (
    <Link
    to="/show" state={produit}
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage src={impp}  
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {nomUsed}
        </h2>
        <span
          className={`  mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
           <span className="text-2xl font-extrabold text-red-400"> {prix}</span>  <span className="text-xxs mr-3 font-extrabold text-red-400">{t('ouguiya')}</span>        
         </span>
      </div>
    </Link>
  );
};

export default CardCategory3;
